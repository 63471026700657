










































































import http from '@/http';
import './scss/PopupSignature.scss';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { formatDate } from '@utility/formatDate';
import {
    getUserCertificates,
    Certificate,
    createDetachedSignature,
    createHash,
    isValidSystemSetup
} from 'crypto-pro';
import { IUserState } from '@store/modules/user/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupSignature',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        FilePreview: () => import('@components/FilePreview/FilePreview.vue'),
    }
})

export default class PopupSignature extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        documentObject: HTMLFormElement,
        documentObjectBox: HTMLFormElement,
    }

    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Action(ProjectDocumentsActions.A_SIGN_DOCUMENT) signDocument!: (data) => Promise<void>

    @Prop({}) docDataUrl!: string;

    docData: any = {};
    selectedFile: any = null;
    authors: { [key: string]: string }[] = [];
    signLoading: boolean = false;

    protected certs: Certificate[] = [];
    private pluginLoading: boolean = false;
    protected noCertLoad = false;
    private certThumbprint: Certificate | null = null;

    get certsFormatted() {
        return this.certs.map(item => {
            return {
                label: item.name,
                ...item,
            }
        });
    }

    get btnDisabled() {
        return !this.certThumbprint || this.signLoading;
    }

    get singleFile() {
        return this.documentIds.length === 1 ? this.documentIds[0] : null
    }

    get urlForViewFile() {
        if (this.selectedFile) {
            return this.selectedFile.linkView;
        } else if (this.docData && this.docData.files && this.docData.files.length) {
            return this.docData.files[0].linkView;
        }
        return '';
    }

    get urlForDownloadFile() {
        if (this.selectedFile) {
            return this.selectedFile.link;
        } else if (this.docData && this.docData.files && this.docData.files.length) {
            return this.docData.files[0].link;
        }
        return '';
    }

    get placeholderForCertificateSelect() {
        return this.certThumbprint ? this.certThumbprint.name : 'Выбор сертификата для подписания';
    }

    get documentIds() {
        if (this.docData && this.docData.files && this.docData.files.length) {
            return this.docData.files.map(el => el.uuid)
        }
        return [];
    }

    get docTitle() {
        if (this.docData && this.docData.title) {
            return this.docData.title;
        }
        return '';
    }

    get docAuthor() {
        if (this.docData && this.docData.author) {
            return this.docData.author;
        }
        return null;
    }

    get projectId() {
        if (this.projectData && this.projectData.id) {
            return this.projectData.id;
        }
        return null;
    }

    get signatures() {
        const output: any[] = [];
        if (this.docData && this.docData.files && !this.selectedFile) {
            this.docData.files.forEach((item) => {
                output.push(...this.addUniqueSignatures(item, output));
            });
        } else if (this.selectedFile) {
            output.push(...this.addUniqueSignatures(this.selectedFile, output));
        }
        return output;
    }

    get selectedFileIsPDFAndSigned() {
        return this.selectedFile && this.selectedFile.format === 'pdf' && this.selectedFile.signatures && this.selectedFile.signatures.length;
    }

    openPopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
            setTimeout(() => {
                if (!this.certs.length) {
                    this.loginPlugin();
                }
            }, 1)
        }
    }

    addUniqueSignatures(file: any, added: any[]) {
        const output: any[] = [];
        file.signatures.forEach((signature) => {
            if (!added.find(
                (item) => item.signer.id === signature.signer.id && item.owner === signature.owner
            )) {
                added.push(signature);
            }
        })
        return output;
    }

    async loginPlugin() {
        this.pluginLoading = true;
        try {
            if (!await isValidSystemSetup()) {
                throw new Error('no valid settings');
            }
            let certs = await getUserCertificates();
            this.certs = certs;
        } catch (e) {
            this.noCertLoad = true;
            console.error(e.message);
        } finally {
            this.pluginLoading = false;
        }
    }

    formattedDate(date) {
        return formatDate({date: new Date(date), format: 'hh:mm dd.mm.yyyy'});
    }

    closePopup() {
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
    }

    changePopupData(data) {
        this.docData = data.allData;
        this.selectedFile = data.selectedFile;
    }

    readFile(item) {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = function () {
                resolve(this.result);
            };
            http.get(item.linkView, {responseType: 'blob'})
                .then((res) => {
                    fileReader.readAsArrayBuffer(res.data)
                })
        });
    }

    onSigning() {
        this.startSign();
    }

    async startSign() {
        this.signLoading = true;
        try {
            let count = 0;
            for (const item of this.docData.files) {
                await this.signFile(item);
                count++;
                if (count === this.docData.files - 1) {
                    this.closePopup();
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.signLoading = false;
            this.$emit('close');
        }
    }

    async signFile(item) {
        const messagePromise = this.readFile(item);
        messagePromise.then((message) => {
            createHash(message as ArrayBuffer).then((hash) => {
                if (this.certThumbprint) {
                    const signaturePromise = createDetachedSignature(this.certThumbprint.thumbprint, hash);
                    signaturePromise.then((signature) => {
                        if (item && item.uuid) {
                            this.signDocument({
                                sign: signature,
                                appId: this.projectId,
                                userId: this.userData.userInfo.id,
                                fileId: item.uuid
                            }).then(() => {
                                this.afterSign();
                            })
                        }
                    })
                }
            })
        })
    }

    afterSign() {
        this.closePopup();
        this.$emit('signed');
    }
}
